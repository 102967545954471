import React from 'react'
import { Layout, Footer, ContactBanner, Section, SEO } from '../../components'
import { useTranslation } from 'react-i18next'

const Commodities = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t('titlePages:commodityExchange')} />
      <Section title='commodities:title' text='commodities:main-text' />
      <ContactBanner />
      <Footer />
    </Layout>
  )
}

export default Commodities
